.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 15px;
}

.title {
  color: var(--darkGray);
  font-family: Bree;
  font-weight: 700;
  font-size: 20px;
}

.label {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
}

.input,
.input :global(.ant-select-selector) {
  background-color: #fbfbfb !important;
  border-color: #d2d2d2 !important;
  border-radius: 4px !important;
}

.itemColumn {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.itemSubmit {
  margin-bottom: 0;
  text-align: right;
  margin-top: 20px;
}

.submit {
  width: 250px;
  font-weight: 700;
  font-size: 13px;
}

:global(.ql-container.ql-snow) {
  height: auto;
}

:global(.ql-editor) {
  min-height: 240px;
  overflow-y: scroll;
}
